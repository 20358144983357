/**
    Popout menu, used in sidebar.
*/
<template>
    <div class="popout">
        <button class="dropbtn"><span class="material-icons smaller">more_horiz</span></button>
        <div class="wrapper">
            <div class="popout-content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Popout',
}
</script>

<style lang="scss">

.dropbtn {
    background-color: var(--color-contrast-low);
    width: 30px;
    border-radius: 4px;
    padding-top: 3px;
    border: none;
    color: var(--color-contrast-full);
    transition-duration: 0.3s;
    &:hover {
        background-color: var(--color-contrast-high);
        cursor: pointer;
    }
}

.popout {
    margin-left: 10px;
    margin-top: 8px;
    display: inline-block;
    &:hover > .wrapper {
        display: block;
    }
    .wrapper {
        position: absolute;
        z-index: 10;
        display: none;
    }
    .smaller {
        font-size: 1.8rem;
        // padding: 0px 4px;
    }
}

.popout-content {
    display: none;
    position: absolute;
    background-color: var(--color-contrast-lower);
    min-width: 180px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    div {
        color: var(--color-contrast-full);
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        &:hover {
            background-color: var(--color-primary);
            color: var(--color-bg);
            cursor: pointer;
        }
    }
    .warning {
        color: var(--color-warning);
        &:hover {
            background-color: var(--color-warning);
            color: var(--color-bg);
            cursor: pointer;
        }
    }
}

.popout:hover .popout-content {
    display: block;
}
</style>
