/**
    Filters component. Used in Custom Charts and Tracker.vue.
*/
<template>
    <Modal 
        ref="editFilter" 
        title="Edit Filter" 
        :instructions="modals.editFilterTitle" 
        @success="updateFiltersFromModal" 
        data-cy="edit-filter-modal"
    >
        <div v-if="editFilterHasMultipleChoice">
            <div>Select Value(s)</div>
            <div class="multi-select-area" data-cy="edit-filter-multi-select-area">
                <div v-for="(item, index) in lookupValues" :key="index" @click="select(item)">
                    <span class="material-icons-outlined">{{ selectedValues.includes(item) ? 'check_box' : 'check_box_outline_blank'}}</span><span class="value-to-pick">{{ displayOption(item) }}</span>
                </div>
            </div>
        </div>
        <div v-else>
            <CustomSelect data-cy="edit-filter-select-area" :options="modals.filterTypes" v-model="modals.selectedType" />
            <input data-cy="edit-filter-input" style="width: 230px;" class="filter-value-input spaced-input" v-model="modals.fieldValue" placeholder="value to filter">
        </div>
    </Modal>
    <Modal 
        ref="addFilter" 
        title="Add Filter" 
        instructions=""
        @success="addFilter" 
        data-cy="add-filter-modal"
    >
        <div class="filter-modal-instructions">Select Field</div>
        <CustomSelect data-cy="filters-sidebar-select-field" style="display: inline-block;" :options="fieldsThatCanBeFiltered()" :groupOrder="fieldGroupOrder" v-model="filterField" />
        <div v-if="filterField">
            <div v-if="showMultipleChoice">
                <div>Select Value(s)</div>
                <div class="multi-select-area" data-cy="filter-multi-select-area">
                    <div v-for="(item, index) in lookupValues" :key="index" @click="select(item)">
                        <span class="material-icons-outlined">{{ selectedValues.includes(item) ? 'check_box' : 'check_box_outline_blank'}}</span><span class="value-to-pick">{{ displayOption(item) }}</span>
                    </div>
                </div>
            </div>
            <div v-else>
                <div>Select Filter Type</div>
                <CustomSelect data-cy="filters-sidebar-select-filtertype" :options="filterTypes" v-model="filterDropdown.type" />
                <input data-cy="filters-sidebar-input" class="filter-value-input spaced-input" v-model="filterDropdown.inputFieldValue" placeholder="value to filter">
            </div>
        </div>
    </Modal>
    <div class="sidebar-section" data-cy="filter-sidebar-section">
        <div class="sidebar-section-title">
            Filters
        </div>

        <p v-if="amountOfStudents && filters.length === 0" class="student-count">{{ amountOfStudents }} Students</p>
        <p v-else-if="amountOfStudents && filters.length > 0" class="student-count">{{ amountOfStudents }} Students Filtered</p>
        <div v-for="(item, index) in filters" :key="index" class="filter-entry">
            {{ item.displayName }} {{ item.displayType }} {{ getFilterDisplayValue(item) }}
            <span 
                data-cy="edit-filter-button"
                class="material-icons edit-filter-button" 
                @click="launchEdit(item)"
                title="Edit Filter"
                @mouseover="showTooltip('Edit filter')"
                @mouseleave="showTooltip('')"
            >
                edit
            </span>
            <span
                data-cy="delete-filter-button"
                class="delete-message-button" 
                @click="deleteFilter(item)"
                title="Delete Filter"
                @mouseover="showTooltip('Delete Filter')"
                @mouseleave="showTooltip('')"
            >
                ✖
            </span>
        </div>
        <CustomButton 
            style="margin-top:15px;"
            data-cy="add-filter-button"
            @click="launchAdd" 
            buttonText="Add Filter" 
            @mouseover="showTooltip('Add filter')"
            @mouseleave="showTooltip('')"
        />
        <CustomButton 
            data-cy="clear-filters" 
            style="margin-top:15px;"
            v-if="filters.length" 
            @click="clearFilters" 
            buttonText="Clear Filters" 
            @mouseover="showTooltip('Clear all filters')"
            @mouseleave="showTooltip('')"
        />
    </div>
</template>

<script>
import Modal from '@/components/modals/modal.vue';
import CustomSelect from '@/components/customSelect.vue';
import CustomButton from '@/components/customButton.vue';
import { getValueFromObject, getGroupValue, getDisplayValue } from '@/functions/utils.js';

const numericFilterTypes = [
    { displayName: '=', tabulatorFunction: 'customEquals' },
    { displayName: '>', tabulatorFunction: '>' },
    { displayName: '<', tabulatorFunction: '<' },
    { displayName: '≥', tabulatorFunction: '>=' },
    { displayName: '≤', tabulatorFunction: '<=' }
];

const textFilterTypes = [
    { displayName: 'contains', tabulatorFunction: 'like' },
    { displayName: 'is exactly', tabulatorFunction: '=' }
];

const allFilterTypes = numericFilterTypes.concat(textFilterTypes);

export default {
    props: {
      amountOfStudents: {
        required: false,
        type: Number
      },
    },
    data() {
        return {
            filterField: '',
            filterDropdown: {
                type: '',
                value: '',
                multipleChoiceSelected: ''
            },
            currentField: {},
            filters: [],
            filterBeingEdited: null,
            editFilterHasMultipleChoice: false,
            selectedValues: [],
            modals: {
                editFilterTitle: '',
                fieldValue: '',
                filterTypes: [],
                selectedType: '',
            }
        }
    },
    emits: ['filtersChanged'],
    components: {
        CustomSelect, CustomButton, Modal
    },
    computed: {
        showMultipleChoice: function () {
            // decide if the filter options area should show a multiple choice
            if (!this.filterField) return false;
            const fieldType = this.config.fields.find(field => field.displayName == this.filterField).type;
            const types = ['category', 'boolean', 'tags'];
            return types.includes(fieldType);
        },
        lookupValues: function () {
            // used for populating multiple choice values for sidebar filter in category and boolean fields
            const field = this.filterBeingEdited ?
                this.config.fields.find(field => field.key == this.filterBeingEdited.field):
                this.getFieldByDisplayName(this.filterField);
            
            if (!field) {
                //suspect we are getting here when _myTags is coming up blank.
                //logging for future debugging.
                console.log({
                    'filterBeingEdited': this.filterBeingEdited,
                    'filterField': this.filterField,
                });
                return [];
            }
            this.currentField = field;
            if (field.key === '_myTags') return ['star', 'flag' ,'warning', 'help', 'done'];

            if (field.key === 'postSecTags') return this.store.state.postSecTags.map(tag => tag.title).concat('No Tag');

            if (field.key === 'freshSuccessTags') return this.store.state.freshSuccessTags.map(tag => tag.title).concat('No Tag');

            return ['Select All'].concat(
                this.getLookupValues({field})
                    .sort((a, b) => this.compareLookupValues(a, b))
            )
        },
        filterTypes: function () {
            // populate available filter operations depending on the type of field to be filtered
            if (!this.filterField) return [];
            const fieldType = this.config.fields.find(field => field.displayName == this.filterField).type;
            if (!fieldType) return [];
            return (fieldType === 'numeric') ? numericFilterTypes : textFilterTypes;
        },
        canAddFilter: function () {
            if (this.showMultipleChoice) {
                return this.filterDropdown.multipleChoiceSelected;
            }
            if (!this.filterDropdown.type) {
                return false;
            }            
            if (!this.filterDropdown.inputFieldValue) {
                return false;
            }
            return true;
        },
        fieldGroupOrder: function () {
            return this.config.fieldGroups.map(e => e.name);
        }
    },
    methods: {
        compareLookupValues(a, b) {
            if (a === null) return 1;
            if (b === null) return -1;
            if (typeof a === 'number' || typeof b === 'number') return a > b ? 1 : -1;
            return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
        },
        displayOption(item) {
            if (item === 'Select All') return item;
            if (this.currentField.type === 'boolean') return item;
            return getDisplayValue({field: this.currentField, value: item});
        },
        launchAdd(fieldKey = null) {
            if (fieldKey) {
                this.filterField = this.config.fields.find(f => f.key == fieldKey)?.displayName || '';
                //TECH DEBT: Why is this field being assigned back to back? what is it trying to do?
                this.filterBeingEdited = 'x';
                this.filterBeingEdited = null;
            }
            this.selectedValues = [];
            this.$refs.addFilter.show();
        },
        
        select(item) {
            //removing selection
            if (this.selectedValues.includes(item)) {
                this.selectedValues = this.selectedValues.filter(element => element !== item);

                if (item === 'Select All') {
                    this.selectedValues = [];
                } else {
                    this.selectedValues = this.selectedValues.filter(element => element !== 'Select All');
                }
            
                //adding selection
            } else {
                this.selectedValues.push(item);
                if (item === 'Select All') {
                    this.selectedValues = this.lookupValues;
                } else if (this.currentField.key !== 'postSecTags' || this.currentField.key !== 'freshSuccessTags') {
                    //change this because of 'No Tag'
                    if (this.selectedValues.length == this.lookupValues.length - 1) {
                        this.selectedValues.push('Select All');
                    }
                }
            }
        },
        launchEdit(filter) {
            this.filterBeingEdited = filter;
            const myField = this.config.fields.find(field => field.key == filter.field);
            const fieldType = myField?.type;
            if (!fieldType) return;

            const types = ['category', 'boolean', 'tags'];
            this.editFilterHasMultipleChoice = types.includes(fieldType); 

            this.modals.editFilterTitle = `Edit filter: ${myField.displayName}`;

            if (this.editFilterHasMultipleChoice) {
                if (fieldType === 'boolean') this.selectedValues = this.filterBeingEdited.value.map(element => getDisplayValue({field: myField, value: element}));
                if (fieldType === 'category') this.selectedValues = this.filterBeingEdited.value;
            } else {

                this.modals.fieldValue = filter.value.toString();
                this.modals.filterTypes = fieldType === 'numeric' ?
                    numericFilterTypes:
                    textFilterTypes;
                this.modals.selectedType = filter.displayType;
            }

            this.$refs.editFilter.show();
        },
        getFilterDisplayValue(item) {
            if (Array.isArray(item.value)) {
                if (item.value.length == 0) return 'None';
                if (item.value.length == 1) {
                    const field = this.config.fields.find(f => f.key == item.field);
                    let value = item.value[0];

                    if (field.key === 'postSecTags') value = (value === 'No Tag' ? value : this.store.state.postSecTags.find(tag => tag.docID === value).title);
                    if (field.key === 'freshSuccessTags') value = (value === 'No Tag' ? value: this.store.state.freshSuccessTags.find(tag => tag.docID === value).title);
                    return getDisplayValue({field: field, value: value})
                }
                return '(Multiple)';
            }
            return getDisplayValue({field: this.config.fields.find(f => f.key == item.field), value: item.value})
        },
        updateFiltersFromModal() {
            const myField = this.config.fields.find(field => field.key == this.filterBeingEdited.field);

            if (!this.editFilterHasMultipleChoice) {
                this.filterBeingEdited.value = getGroupValue({field: myField, value: this.modals.fieldValue});
                this.filterBeingEdited.displayType = this.modals.selectedType;
                this.filterBeingEdited.type = allFilterTypes.find(e => e.displayName == this.modals.selectedType).tabulatorFunction;
            } else {
                this.filterBeingEdited.value = this.selectedValues
                    .map(element => myField.type === 'boolean' ? 
                        getGroupValue({field: myField, value: element}): 
                        element)
                    .filter(element => element !== 'Select All' && element !== undefined);
                
                //need to reverse engineer this to using the tag id instead of the label
                //TECH DEBT: filters are so convoluted and I am returning to this task after 5 months. not sure if this is the right move but it works.
                if (this.filterBeingEdited.field === 'postSecTags') this.filterBeingEdited.value = this.filterBeingEdited.value.flatMap(tagName => {
                    if (tagName === 'No Tag') return tagName;

                    //only return a tag.docID if the tag name matches
                    return this.store.state.postSecTags.map(tag => tag.title === tagName ? tag.docID : null);
                })
                //get rid of any null options in the array
                .filter(n => n);

                if (this.filterBeingEdited.field === 'freshSuccessTags') this.filterBeingEdited.value = this.filterBeingEdited.value.flatMap(tagName => {
                    if (tagName === 'No Tag') return tagName;

                    //only return a tag.docID if the tag name matches
                    return this.store.state.freshSuccessTags.map(tag => tag.title === tagName ? tag.docID : null);
                })
                //get rid of any null options in the array
                .filter(n => n);
            }

            this.$emit('filtersChanged', this.filters);
            this.filterBeingEdited = null;
        },
        addFilter({
            filterArr = [this.getFilterFromSidebar()]
        } = {}) {
            this.filters = this.filters.concat(filterArr);
            this.resetFilterDropdowns();
            this.$emit('filtersChanged', this.filters);
        },
        getFilterFromSidebar({
            filterField = this.filterField,
            filterType = this.getFilterTypeFromSidebar(),
            field = this.getFieldByDisplayName(this.filterField),
            value = this.getFilterValueFromSidebar(),
        } = {}) {

            let type = filterType.type;
            if (field.key === '_myTags') type = 'myTagFilter';
            if (field.key === 'postSecTags') type = 'tagsFilter';
            if (field.key === 'freshSuccessTags') type = 'freshSuccessTagsFilter';

            const result = {
                displayName: filterField, // not used by Tabulator
                field: field.key,
                type,
                displayType: filterType.displayType, // not used by Tabulator
                value
            };
            return result;
        },
        getFilterTypeFromSidebar({
            selectedType = this.filterDropdown.type,
            filterTypes = this.filterTypes,
            isMultipleChoiceField = this.showMultipleChoice
        } = {}) {
            return {
                type: isMultipleChoiceField ?
                    'customIn' :
                    filterTypes.find(element => element.displayName == selectedType).tabulatorFunction,
                displayType: isMultipleChoiceField ? ':' : selectedType,
            }
        },
        getBoolean(arr, field) {
            if (!field.options?.true) {
                console.error('Boolean field is missing options parameter');
                return arr;
            }
            return arr.map(element => {
                return field.options.true.toLowerCase() == element.toLowerCase() ? 
                    true : 
                    field.options.false.toLowerCase() == element.toLowerCase() ?
                        false :
                        element === 'No data' ? null : element;
            })
        },
        getFilterValueFromSidebar({
            field = this.getFieldByDisplayName(this.filterField),
            isMultipleChoiceField = this.showMultipleChoice,
            inputFieldValue = this.filterDropdown.inputFieldValue,
            multipleChoiceSelected = this.selectedValues //this.filterDropdown.multipleChoiceSelected
        } = {}) {

            if (!isMultipleChoiceField) return inputFieldValue;

            //return tag ID instead here
            if (field.key === 'postSecTags') {
                return multipleChoiceSelected.flatMap(tagName => {
                    if (tagName === 'No Tag') return tagName;

                    //only return a tag.docID if the tag name matches
                    return this.store.state.postSecTags.map(tag => tag.title === tagName ? tag.docID : null);
                })
                //get rid of any null options in the array
                .filter(n => n);
            }

            //return tag ID instead here
            if (field.key === 'freshSuccessTags') {
                return multipleChoiceSelected.flatMap(tagName => {
                    if (tagName === 'No Tag') return tagName;

                    //only return a tag.docID if the tag name matches
                    return this.store.state.freshSuccessTags.map(tag => tag.title === tagName ? tag.docID : null);
                })
            }

            return field.type === 'boolean' ?
                this.getBoolean(multipleChoiceSelected, field) :
                multipleChoiceSelected;
        },
        setFilters(filters) {
            //TECH DEBT: everytime there are filters to set, it rerenders the Student lists table. Was getting an emtpy array but it was still passing `!filters`
            //the whole filters system is wonky and needs reworked.
            if (!filters) return;
            this.filters = filters
                .filter(element => element !== undefined)
                .map(element => {
                    // Convert legacy filters that use a value instead of an array for filtering boolean or category fields
                    const myField = this.config.fields.find(field => field.key === element.field);
                    if (!myField) return;
                    if (myField.type === 'boolean' || myField.type === 'category') {
                        if (Array.isArray(element.value)) return element;
                        element.value = [element.value];
                        element.type = 'customIn';
                        return element;
                    }
                    return element;
                });

            this.$emit('filtersChanged', this.filters);
        },
        deleteFilter(filter) {
            // remove single filter
            this.filters = this.filters.filter(element => element !== filter);
            this.resetFilterDropdowns();
            this.$emit('filtersChanged', this.filters);
        },
        deleteFiltersFromKey(key) {
            this.filters = this.filters.filter(element => element.field !== key);
            this.resetFilterDropdowns();
            this.$emit('filtersChanged', this.filters);
        },
        clearFilters() {
            // clear all filters
            this.filters = [];
            this.resetFilterDropdowns();
            this.$emit('filtersChanged', this.filters);
        },
        resetFilterDropdowns() {
            this.filterDropdown.multipleChoiceSelected = '';
            this.filterDropdown.inputFieldValue = '';
            this.filterField = '';
        },
        getLookupValues({
            field,
            studentData = this.store.state.studentData,
            getValueFromObject = this.getValueFromObject,
        } = {}) {
            const getVal = (record, key) => {
                const rawValue = getValueFromObject(record, key);
                return Array.isArray(rawValue) ?
                    rawValue[0].value:
                    rawValue;
            };

            const getCategoryOptions = (data, key) => [...new Set(
                data.map(record => getVal(record, key))
            )];

            const getBooleanOptions = boolField => [
                boolField.options?.true || 'true',
                boolField.options?.false || 'false',
                'No data'
            ];

            const result = field.type === 'boolean' ? 
                getBooleanOptions(field):
                field.type === 'category' ?
                    getCategoryOptions(studentData, field.key):
                    [];

            return result;
        },
        fieldsThatCanBeFiltered() {
            return this.config.fields
                .filter(element => !element.hideInFilters)
                .filter(element => element.showInGrades.includes(this.store.state.showingGradeLevel))
                .sort((a, b) => a.displayName > b.displayName ? 1 : -1);
        },
        getFieldByDisplayName(name) {
            return this.config.fields.find(field => field.displayName == name)
        },
        getValueFromObject(...args) {
            return getValueFromObject(...args)
        },
    }
}
</script>

<style lang="scss">

.edit-filter-button {
    font-size: 16px;
    &:hover {
        color: var(--color-primary);
        cursor: pointer;
    }
}

.add-filter-button {
    margin-left: 5px;
    margin-top: 20px;
}

.filter-entry {
    margin-left: 10px;
    margin-top: 10px;
}

.filter-modal-instructions {
    margin-left: 0px;
    margin-top: 15px;
}

.filter-value-input {
    width: 225px;
    height: 35px;
    border: none;
    border-radius: 3px;
}

.multi-select-area {
    background-color: var(--color-bg);
    border-radius: 5px;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    max-height: 230px;
    overflow-y: auto;
    .value-to-pick {
        position: relative;
        top: -5px;
        left: 5px;
    }
}

.student-count {
    margin: 10px 0 0 10px;
}

.student-count::after {
  content: "\a";
  white-space: pre;
}

</style>
